import React from 'react'
import { connect } from 'react-redux'
import { FieldArray, reduxForm } from 'redux-form'
import { I18n } from 'react-i18next'
import {
    initialize,
    getFormValues,
    isPristine,
    isSubmitting,
    setSubmitFailed,
    setSubmitSucceeded,
    isValid
} from 'redux-form'
import { change } from 'redux-form'
import AccordionComponent from '../../../../components/AccordionComponent'
import { OBJECT_FORM } from './Form'
import {
    getFieldEditFields,
    getFieldListFields,
    getLanguage,
    getModule
} from '../../selectors'
import _ from 'lodash'
import getFormValidations from './formValidations'
import ValidationUI from '../../../../components/ValidationUI'
import {setFieldWidth} from "../../actions";

const getFormName = field => `${field.path}${_.upperFirst(OBJECT_FORM)}`

const arrayToObject = (array = []) =>
    array.reduce((obj, item, index) => {
        item.id ? (obj[item.id] = item) : (obj[index] = item)
        return obj
    }, {})

const AccordionComponentWithReduxForm = reduxForm()(AccordionComponent)

// binding for parend redux-form, using a child redux-form
const VisualComponent = ({
    fields: { push, remove, move, getAll },
    meta: { error, submitFailed },
    path,
    required,
    onChange,
    formName,
    module,
    noRowsMessage,
    newable,
    removable,
    sortable,
    editable,
    disabled,
    listFields,
    fixedHeight,
    failSubmit,
    succeedSubmit,
    defaultSortBy,
    defaultSortDirection,
    t,
    language,
    ...props
}) => {
    const objects = getAll()

    const allIds = objects && objects.map((o, i) => o.id || i)
    const byId = arrayToObject(objects)

    const translatedError = t(error)

    return (
        <I18n ns={[module.model, 'platform']}>
            {t => (
                <div>
                    <AccordionComponentWithReduxForm
                        path={path}
                        required={required}
                        objects={objects}
                        byId={byId}
                        allIds={allIds}
                        listFields={listFields}
                        failSubmit={failSubmit}
                        succeedSubmit={succeedSubmit}
                        push={push}
                        remove={remove}
                        move={move}
                        onFieldChange={onChange}
                        form={formName}
                        formField={true}
                        newable={newable}
                        removable={removable}
                        editable={editable}
                        disabled={disabled}
                        sortable={sortable}
                        t={t}
                        language={language}
                        noRowsMessage={noRowsMessage}
                        fixedHeight={fixedHeight}
                        module={module}
                        defaultSortBy={defaultSortBy}
                        defaultSortDirection={defaultSortDirection}
                        {...props}
                    />
                    {submitFailed && (error && <ValidationUI error={translatedError} />)}
                </div>
            )}
        </I18n>
    )
}

const FormAccordionField = ({
    groupModel,
    field,
    onChange,
    initialize,
    editObjectValues,
    setSubmitFailed,
    setSubmitSucceeded,
    t,
    language,
    objectMode,
    ...props
}) => {
    const validations = getFormValidations(field, t, [], editObjectValues)
    return (
        <FieldArray
            name={field.path}
            path={field.tKey || field.path}
            required={field.required}
            formName={getFormName(field)}
            component={VisualComponent}
            groupModel={groupModel}
            editObjectValues={editObjectValues}
            rerenderOnEveryChange={false}
            onChange={onChange}
            validate={validations}
            fixedHeight={field.height}
            initialize={initialize}
            succeedSubmit={setSubmitSucceeded}
            failSubmit={setSubmitFailed}
            maxRows={field.maxRows}
            noRowsMessage={field.noRowsMessage}
            removable={field.removable}
            newable={field.newable}
            sortable={field.sortable}
            disabled={field.disabled || !field.writable}
            editable={!field.editable ? objectMode === 'newObject' : field.editable  }
            defaultSortBy={field.defaultSortBy}
            defaultSortDirection={field.defaultSortDirection}
            sortableTable={field.sortableTable}
            t={t}
            language={language}
            {...props}
        />
    )
}

const mapStateToProps = (state, { field }) => ({
    module: getModule(state),
    formValues: getFormValues(getFormName(field))(state),
    isPristine: isPristine(getFormName(field))(state),
    isSubmitting: isSubmitting(getFormName(field))(state),
    isValid: isValid(getFormName(field))(state),
    listFields: getFieldListFields(state, field.id),
    editFields: getFieldEditFields(state, field.id),
    language: getLanguage(state)
})

const mapDispatchToProps = (dispatch, { field }) => ({
    onChange: (index, value) =>
        dispatch(change(OBJECT_FORM, `${field.path}[${index}]`, value)),
    initialize: object => dispatch(initialize(getFormName(field), object)),
    setSubmitFailed: fields =>
        dispatch(setSubmitFailed(getFormName(field), ...fields)),
    setFieldWidth: (fieldId, width) => dispatch(setFieldWidth(fieldId, width)),
    setSubmitSucceeded: () => dispatch(setSubmitSucceeded(getFormName(field)))
})

export default connect(mapStateToProps, mapDispatchToProps)(FormAccordionField)
