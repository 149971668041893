import {generateReportButton} from "../utils"
import {returnButton} from "../../utils"
import {GET_OBJECT_SUCCESS} from "../../../../../apps/KpModule/actions/api";
import {setFieldEdition} from "../../../../../apps/KpModule/actions";

const liberalityFilePdfFields = [
    'id', 'applicationNumber', 'administrator.fullName', 'beneficiary.name', 'zipCode', 'fullName', 'testator', 'testatorComment', 'object',
    'grossAssets', 'netForecast', //'netAssets',
    'distribution.beneficiary.name', 'distribution.beneficiary.zipCode', 'distribution.qualification.name', 'distribution.share',
    'grossLiabilities', 'actsFees', 'lifeInsuranceNetAmount', 'status.id', 'statusName'
]

export const module_ = {
    object: 'LiberalityPresentation',
    name: "Report",
    tKey: 'Relevé de décisions',
    objectIdentifier: 'applicationNumber',
    newable: false,
    removable: false,
    defaultFormButtons: [generateReportButton, returnButton],
    category: {
        path: 'Libéralités',
        icon: 'folder'
    },
    viewMap: {
        dt: [
            {path: 'presentationDate', tKey: 'Présentation'},
            {path: 'recipients', display: 'fullName', tKey: 'Destinataires'},
            {path: 'reportGeneratedBy', tKey: 'Utilisateur'},
            {path: 'report'}
        ],
        form: {
            fields: [
                {path: 'presentationDate', tKey: 'Date de présentation', disabled: true},
                {path: 'recipients', display: 'fullName', fieldPath: ['id', 'firstname', 'fullName'], tKey: 'Destinataires', filters: ['eligibleToLiberalityProcess'], required: false},
                {path: 'presentationDescription', tKey: 'Présentation', type: 'richTextEditor', disabled: true},
                {path: 'reportDescription', tKey: 'Relevé de décisions', type: 'richTextEditor'},
                {path: 'liberalityFiles', fieldPath: liberalityFilePdfFields, hidden: true},
                {path: 'status', hidden :true},
                {path: 'buttons', hidden :true}
            ]
        }
    },
    actionSubscriptions: [
        {
            actionType: GET_OBJECT_SUCCESS,
            subscription: ({ store }) => {
                const state = store.getState()
                const presentationStatus = state.edit.object.data.status

                store.dispatch(setFieldEdition('e_recipients', presentationStatus !== 'closed'))
                store.dispatch(setFieldEdition('e_reportDescription', presentationStatus !== 'closed'))

            }
        }
    ]
}
