import _ from "lodash"
import moment from "moment"
import {decrypt} from "../../../utils/crypto"
import path from "path"
import Errors from "../../../utils/Errors"
import {basicContext} from "../../../utils/contextUtils"
import {getButtonsForModule} from "./utils"
import {liberalityApplicationStatusObjects} from "../staticEntities"
import async from "async"

export const entities = [
    {
        name: 'LiberalityFilePart',
        fields: [
            // temporary
            /*
            {path: 'typeOfFile', type: 'TypeOfLiberalityFile', nullable: true},
            {type: 'TypeOfProperty', nullable: true},
            {type: 'Group', nullable: true},

             */

            {path: 'confFor', type: 'boolean'},
            {path: 'beneficiary', type: 'Organization'},
            {type: 'Qualification', nullable: true},
            {path: 'typesOfProperty', type: 'TypeOfProperty', link: 'MTM', nullable: true},
            {path: 'ownLiabilities', type: 'decimal', nullable: true},
            {path: 'share', type: 'decimal', tKey: 'Quote-part'},
            'Group'
        ]
    },
    {
        name: 'LiberalityFile',
        facets: ['files', 'comments'],
        fields: [
            // temporary
            {path: 'beneficiary', type: 'Organization', nullable: true},

            {path: 'distribution', type: 'LiberalityFilePart', link: 'OTM'},
            {path: 'applicationNumber', type: 'string', unique: true},
            {
                path: "sequence",
                unique: true,
                ps: {
                    object: [{
                        type: "nextSequence",
                        sequenceId: "liberalityApplicationSequence",
                        formatResult: function(result) {
                            return result.toString().padStart(4, '0') // Formats the sequence number, e.g., "0001"
                        }
                    }]
                }
            },

            /* Parties */
            {path: 'administrator', type: 'CUser'},
            {path: 'typeOfFile', type: 'TypeOfLiberalityFile'},
            {
                path: 'zipCode',
                fieldPath: ['beneficiary.zipCode', 'lifeInsuranceNetAmount'],
                f: function () {
                    return this.beneficiary?.zipCode
                }
            },
            {path: 'benefactor'},
            {path: 'comment'},

            {path: 'grossAssets', type: 'integer', nullable: true},
            {path: 'grossAssetsDetails'},

            {path: 'assets', type: 'integer', nullable: true},
            {path: 'assetsDetails'},

            {path: 'grossLiabilities', type: 'integer'},
            {path: 'grossLiabilitiesDetails'},

            {path: 'actsFees', type: 'integer'},
            {path: 'actsFeesDetails'},

            {
                path: 'netForecast',
                fieldPath: ['typeOfFile.id', 'grossAssets', 'assets', 'grossLiabilities', 'actsFees',
                    'distribution.id'
                ],
                f: function() {
                    const typeOfFileId = _.get(this, 'typeOfFile.id')
                    const totalSharePercentage = this.distribution.reduce((total, item) => {
                        return total + (item.share/100 || 0);
                    }, 0)
                    const totalOwnLiabilities = this.distribution.reduce((total, item) => {
                        return total + (item.ownLiabilities || 0);
                    }, 0)
                    switch (typeOfFileId) {
                        case 'legs':
                            return (this.grossAssets - this.grossLiabilities - this.actsFees) * totalSharePercentage - totalOwnLiabilities
                        case 'lifeInsurance':
                            return this.assets
                        case 'donations':
                        case 'giftsOnInheritance':
                            return (this.grossAssets - this.actsFees) * totalSharePercentage
                        default:
                            return 0
                    }
                }
            },

            {
                path: 'beneficiaries',
                fieldPath: ['distribution.beneficiary.zipCodeAndName'],
                f: function () {
                    return this.distribution.map(share => _.get(share, 'beneficiary.zipCodeAndName')).join(', ')
                }
            },

            {
                path: 'CNDABeneficiaries',
                fieldPath: ['distribution.beneficiary.zipCodeAndName'],
                f: function () {
                    return this.distribution.filter(share => share.confFor)
                        .map(share => _.get(share, 'beneficiary.zipCodeAndName')).join(', ')
                }
            },

            {
                path: 'CNDAShare',
                fieldPath: [
                    //'netAssets',
                    'netForecast'
                ],
                f: function () {
                    if(!this.netForecast) return 0
                    const share = (this.netForecast * 6) / 100
                    return _.round(share, 2)
                }
            },
            {
                path: 'totalAmount',
                fieldPath: [
                    //'netAssets',
                    'netForecast',
                    'lifeInsuranceNetAmount'],
                f: function () {
                    //const netAssets = this.netAssets || 0
                    const netForecast = this.netForecast || 0
                    const lifeInsuranceNetAmount = this.lifeInsuranceNetAmount || 0
                    return netForecast + lifeInsuranceNetAmount
                }
            },

            {path: 'status', type: 'LiberalityApplicationStatus', nullable: true},
            {
                path: 'statusName',
                fieldPath: ['status.id'],
                f: function () {
                    const statusObject = liberalityApplicationStatusObjects.find(status => status.id === this.status.id)
                    return statusObject?.name
                }
            },

            {path: 'createdAt', type: 'date', nullable: true},
            {path: 'updatedAt', type: 'date', nullable: true},
            {path: 'updatedBy', type: 'User', nullable: true},

            {
                path: "presentationDate",
                fieldPath: ['id'],
                $f: function (file, context, callback) {
                    global.app.C.LiberalityPresentation.collection
                        .find({liberalityFiles: {$elemMatch: {$eq: global.ObjectID(file.id)}}})
                        .sort({ presentationDate: -1 })
                        .limit(1)
                        .toArray((e, presentations) => {
                            const presentation = presentations[0]
                            if(!presentation) return callback(null, '')
                            callback(null, presentation.presentationDate)
                        })
                }
            },
            {
                path: "buttons",
                fieldPath: ['status.id'],
                $f: function (file, context, callback) {
                    const buttons = getButtonsForModule(file, context);
                    callback(null, buttons);
                }
            },
            {
                path: "noDeleteButtonAccess",
                fieldPath: ['status.id'],
                f: function () {
                    const statusId = _.get(this, 'status.id')
                    return !['draft', 'incomplete', 'readyToBePresented', 'updated'].includes(statusId)
                }
            },
        ],
        filters: [
            {
                name: 'byStatus',
                title: 'Status',
                type: "tags",
                path: 'status',
                object: 'LiberalityApplicationStatus',
                display: 'name',
                default: [{id: 'draft'}, {id: 'incomplete'}],
                client: true,
                isDefault: false,
                timer: 3,
                query: context => {
                    const statuses = _.get(context , "data.status");

                    if(!statuses || statuses.length === 0) return {}
                    return {status: {$in: statuses.map(o => o.id)}};
                }
            },
            {
                name: "readyTobePresented",
                isDefault: false,
                query: () => {
                    return {status: {$in: ['readyToBePresented', 'updated']}}
                }
            }
            /*
            {
                name: "byUser",
                isDefault: false,
                async: true,
                query: (context, callback) => {
                    global.app.C.CUser.collection.findOne({kpUser: global.ObjectID(context.user.id)}, (e, cUser) => {
                        if(!cUser) return callback(null, {_id: null})
                        return callback(null, {mail: cUser.mail})
                    })
                }
            }
             */
        ],
        ps: {
            context: [{
                $$u: function (context, callback) {
                    if (this.options.accessType === "S" && context.restAction && context.restAction.crudType === "C") {
                        context.internalFieldPath = [
                            ...new Set([
                                ...context.internalFieldPath,
                                "sequence" // Ensures 'sequence' is included for sequence generation on creation
                            ])
                        ];
                    }
                    callback(null, context)
                }
            }]
        },
        requiredAuthorization: async (context, callback) => {
            const moduleId = context.clientContext.moduleId
            if(moduleId === 'm-C-supportModule') return callback()
            const functions = await global.app.C.Function.find({
                ...basicContext(context),
                query: {},
                fieldPath: ['id'],
                filters: ['liberalityProcessAdministrator']
            })

            const cUser = await global.app.C.CUser.collection.findOne({
                kpUser: global.ObjectID(context.user.id),
                functions: {$elemMatch: {$in: functions.map(func => global.ObjectID(func.id))}}
            })
            if(!cUser) return callback(new Errors.ValidationError("Autorisation requise manquante"))
            return callback()
        },
        duplicateBeneficiaries: (object, context, callback) => {
            const beneficiaryIds = object.distribution.map(item => item.beneficiary?.id)

            const seen = new Set()
            const duplicates = beneficiaryIds.filter(id => {
                if (seen.has(id)) {
                    return true
                }
                seen.add(id);
                return false
            })

            if (duplicates.length > 0) {
                return callback(new Errors.ValidationError('Bénéficiaires en double trouvés'))
            }

            return callback()
        },
        validateTotalShares: (object, context, callback) => {
            const totalShare = object.distribution.reduce((total, item) => {
                return total + (item.share || 0);
            }, 0)

            if (totalShare > 100) {
                return callback(new Errors.ValidationError(`La somme des parts doit être inférieur ou égale à 100, actuellement : ${totalShare}`));
            }

            return callback()
        },
        presentationDateRequirement: (object, context, callback) => {
            const moduleId = context.clientContext.moduleId

            if(moduleId === 'm-C-liberalityDecision' && object.presentationDate > moment().format('YYYY-MM-DD')) {
                return callback(new Errors.ValidationError("Vous ne pouvez pas appliquer une décision pour un dossier dont la date de présentation est postérieure à la date du jour"))
            }
            return callback()
        },
        validateSave: async function(object, oldObject, context, callback){
            async.series([
                callback => this.requiredAuthorization(context, callback),
                callback => this.duplicateBeneficiaries(object, context, callback),
                callback => this.validateTotalShares(object, context, callback),
                callback => this.presentationDateRequirement(object, context, callback),
            ], callback)
        },
        beforeSave: (object, oldObject, context, callback) => {

            /*
            object.updatedAt = new Date()
            object.updatedBy = _.pick(context.user, ['id', 'name'])
             */

            if (context.restAction && context.restAction.crudType === "C") {

                const currentYear = new Date().getFullYear()
                object.applicationNumber = `LIB-${currentYear}-${object.sequence}`
                object.createdAt =  new Date()
                object.status = {id: 'draft'}
                object.comments.push(
                    {
                        user: _.pick(context.user, ['id', 'name']),
                        text: "Brouillon",
                        date: moment().format("YYYY-MM-DD HH:mm")
                    }
                )
                return callback(null, object, oldObject)
            }

            object.lastComment = _.last(_.orderBy(object.comments, 'date')).text

            const action = context.action
            if(action === 'save') return callback(null, object, oldObject)
            const actionButton = object.buttons.find(button => button.action === action)
            object.status = {id: actionButton.nextStatus}
            const statusObject = liberalityApplicationStatusObjects.find(status => status.id === object.status.id)
            object.comments.push({
                user: _.pick(context.user, ['id', 'name']),
                text: statusObject.name,
                date: moment().format("YYYY-MM-DD HH:mm")
            })
            return callback(null, object, oldObject)
        },
        afterSave: async function(object, oldObject, context, callback){
            const action = context.action
            if(action === 'save') return callback()

            try {
                //await this.handleMailing(object, context)
                return callback()
            } catch (error) {
                callback(error)
            }
        },
        handleMailing: async (object, context) => {
            let mails = []

            //Prepare email to notify the submitter
            const notificationEmail = prepareNotificationEmail(object, context)

            if(notificationEmail) mails.push(notificationEmail)

            const status = _.get(object, 'status.id')

            if(['filed', 'filed2', 'updated', 'updated2'].includes(status)) {
                const administrationEmails = await prepareAdministrationEmails(object, context)
                administrationEmails.forEach(mail => mails.push(mail))
            }

            if(status === 'visitInProgress') {
                const visitorsEmails = await prepareVisitEmails(object, context)
                visitorsEmails.forEach(mail => mails.push(mail))
            }

            return global.mailer.sendMail(mails, (error) => {
                if(error) console.log(error)
            })
        }

    }

]

function prepareDefaultMail(context) {

    const replyTo = context.group.useNoReply
        ? context.group.noReply
        : 'support@keenpoint.com'

    const alias = context.group.useNoReply
        ? context.group.alias
        : 'Kp Support'

    return {
        from: `"${alias}"${replyTo}`,
        replyTo: replyTo,
        templateDir: path.join(global.appRoot, global.isProd ? 'buildServer' : 'src' , '/server/models/cnda/adhesion/templates'),
        verbose: {
            general: true,
        }
    }
}

function getNotificationEmailContent(object) {
    let content = {}
    const status = _.get(object, 'status.id')
    switch (status) {
        case 'filed':
            content.subject = `CNDA - Demande d'ahésion N° ${object.applicationNumber} : Volet Administratif : Enregistré`
            content.template = 'user_submission_filed.html'
            break
    }
    return content
}

function prepareNotificationEmail(object, context) {
    const defaultMail = prepareDefaultMail(context)
    const content = getNotificationEmailContent(object, context)

    if(!content.template) return null

    const groupModelId = _.get(context, "groupModel.id", "")

    return _.defaults({
        to: decrypt(object.mail),
        content: content.template,
        subject: { template: content.subject },
        context: {
            firstname: object.firstname,
            applicationNumber: object.applicationNumber,
            lastComment: object.lastComment,
            host: context.host,
            visitors: object.visitors?.map(visitor => visitor.completeInfo),
            baseUrl: global.isProd
                ? `https://${context.host}`
                : `http://localhost:3000`,
            groupModelUrl: global.isProd
                ? `https://${context.host}/business/${groupModelId}`
                : `http://localhost:3000/business/${groupModelId}`,
            customToken: context.customToken
        }
    }, defaultMail)
}

async function prepareAdministrationEmails(object, context) {
    const defaultMail = prepareDefaultMail(context)
    const recipients = await getAdministrators(context)
    const content = {}

    const status = _.get(object, 'status.id')

    switch (status) {
        case 'filed':
            content.template = 'admin_submission_filed.html'
            content.subject = `CNDA - Demande d'ahésion N° ${object.applicationNumber} : Volet Administratif : Enregistré`
            break
    }

    return recipients.map(recipient => {
        return _.defaults(
            {
                to: decrypt(recipient.mail),
                content: content.template,
                subject: { template: content.subject },
                context: {
                    firstname: recipient.firstname,
                    applicationNumber: object.applicationNumber
                }
            },
            defaultMail
        )
    })
}

async function prepareVisitEmails(object, context) {
    const defaultMail = prepareDefaultMail(context)
    const recipients = await getVisitors(object, context)
    const civility = _.get(object, 'civility.name', '')
    const mail = object.mail && decrypt(object.mail)
    const phone = object.phone && decrypt(object.phone)
    const applicantInfo = `${context.tc(civility)} ${object.firstname} ${object.lastname}, ${mail}, ${phone}`
    return recipients.map(recipient => {
        return _.defaults(
            {
                to: decrypt(recipient.mail),
                content: 'visitor_submission_visitInProgress.html',
                subject: { template: `CNDA - Demande d'ahésion N° ${object.applicationNumber} : Visite : Demandée` },
                context: {
                    firstname: recipient.firstname,
                    applicationNumber: object.applicationNumber,
                    applicant: applicantInfo,
                    organization: object.organizationName
                }
            },
            defaultMail
        )
    })
}

async function getAdministrators(context) {
    const functions = await global.app.C.Function.find({
        ...basicContext(context),
        query: {},
        fieldPath: ['id'],
        filters: ['adhesionProcessAdministrator']
    })

    return await global.app.C.CUser.find({
        ...basicContext(context),
        query: {functions: {$elemMatch: {$in: functions.map(func => global.ObjectID(func.id))}}},
        fieldPath: ['id', 'firstname', 'mail']
    })
}

async function getVisitors(object, context) {

    return await global.app.C.CUser.find({
        ...basicContext(context),
        query: {_id: {$in: object.visitors.map(visitor => global.ObjectID(visitor.id))}},
        fieldPath: ['id', 'firstname', 'mail']
    })
}
