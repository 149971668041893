//import {setFieldVisibility} from "../../../../../apps/KpModule/actions"
//import {organizationActivities} from "../../staticEntities";

import {
    changeFieldProperty,
    setFieldEdition,
    setFieldListOptions,
    setFieldVisibility
} from "../../../../../apps/KpModule/actions";
import _ from "lodash";
import {getDataListList} from "../../../../../apps/KpModule/selectors";
import {isArrayInArrayOfArrays} from "../../../suiviEngagement/utils/usefulFunctions";

export const module_ = {
    object: 'LiberalityFile',
    name: 'LiberalityFile',
    tKey: 'Dossier',
    objectIdentifier: 'applicationNumber',
    category: {
        path: 'Libéralités',
        icon: 'folder'
    },
    viewMap: {
        dt: [
            {path: 'applicationNumber', tKey: 'N° dossier'},
            {path: 'presentationDate', tKey: 'Présentation'},
            {path: 'typeOfFile', display: 'code'},
            {path: 'administrator', display: 'fullName', tKey: 'Gestionnaire'},
            {path: 'benefactor'},
            {path: 'beneficiaries', tKey: 'Bénéficiaire(s)'},
            {path: 'CNDABeneficiaries', tKey: 'CNDA Pour'},
            //{path: 'netAssets', tKey: 'Actif net (€)', tooltip: true},
            {path: 'netForecast', tKey: 'Net Prévisionnel (€)', tooltip: true},
            {path: 'CNDAShare', tKey: 'Dont part CNDA'},

            {path: 'status', type: 'status', fieldPath: ['id', 'name', 'bStyle'], width: 280},
            {path: 'updatedAt', tKey: 'Date'},
            {path: 'updatedBy', tKey: 'Utilisateur'},
            {path: 'noDeleteButtonAccess', hidden: true},
        ],
        form: {
            fields: [
                //{path: 'applicationNumber', tKey: 'N° dossier', disabled: true},
                //{path: 'partiesSection', section: "partiesSection", data: 'Parties', type: 'heading', hideLabel: true, style: {margin: '15px 5px 0', fontSize: 'larger', color: 'rgb(255 152 0)', padding: '10px 0'}},

                {
                    path: 'typeOfFile',
                    sortList: false,
                    display: 'fullName',
                    section: "partiesSection",
                    subscriptions: {
                        onChange: (newValue, oldValue, { module, store, formInitialize, getObjectSuccessAction }) => {
                            if(newValue) {
                                const state = store.getState()
                                const isLegs = newValue.id === 'legs'
                                const isLifeInsurance = newValue.id === 'lifeInsurance'
                                const isDonationOrGiftsOnInheritance = ['donations', 'giftsOnInheritance'].includes(newValue.id)

                                const grossAssetsField = module.viewMap.form.fields.find(field => field.path === 'grossAssets')
                                const grossAssetsDetailsField = module.viewMap.form.fields.find(field => field.path === 'grossAssetsDetails')

                                const netAssetsField = module.viewMap.form.fields.find(field => field.path === 'assets')
                                const netAssetsDetailsField = module.viewMap.form.fields.find(field => field.path === 'assetsDetails')


                                const liabilitiesField = module.viewMap.form.fields.find(field => field.path === 'grossLiabilities')
                                const liabilitiesDetailsField = module.viewMap.form.fields.find(field => field.path === 'grossLiabilitiesDetails')
                                const actFeesField = module.viewMap.form.fields.find(field => field.path === 'actsFees')
                                const actFeesDetailsField = module.viewMap.form.fields.find(field => field.path === 'actsFeesDetails')

                                store.dispatch(setFieldVisibility(grossAssetsField.id, !isLifeInsurance))
                                store.dispatch(setFieldVisibility(grossAssetsDetailsField.id, !isLifeInsurance))

                                store.dispatch(setFieldVisibility(netAssetsField.id, isLifeInsurance))
                                store.dispatch(setFieldVisibility(netAssetsDetailsField.id, isLifeInsurance))

                                store.dispatch(setFieldVisibility(liabilitiesField.id, isLegs))
                                store.dispatch(setFieldVisibility(liabilitiesDetailsField.id, isLegs))
                                store.dispatch(setFieldVisibility(actFeesField.id, !isLifeInsurance))
                                store.dispatch(setFieldVisibility(actFeesDetailsField.id, !isLifeInsurance))

                                if(!isLegs) {
                                    liabilitiesField.setValue(0)
                                    liabilitiesDetailsField.setValue('')
                                }

                                if(isLifeInsurance) {
                                    actFeesField.setValue(0)
                                    actFeesDetailsField.setValue('')

                                    grossAssetsField.setValue(0)
                                    grossAssetsDetailsField.setValue('')
                                }

                                if(!isLifeInsurance) {
                                    netAssetsField.setValue(0)
                                    netAssetsDetailsField.setValue('')
                                }

                                const repartitionField = module.viewMap.form.fields.find(field => field.path === 'distribution')

                                const ownLiabilitiesListField = repartitionField.viewMap.dt.fields.find(field => field.path === 'ownLiabilities')
                                const ownLiabilitiesFormField = repartitionField.viewMap.form.fields.find(field => field.path === 'ownLiabilities')

                                const qualificationListField = repartitionField.viewMap.dt.fields.find(field => field.path === 'qualification')
                                const qualificationFormField = repartitionField.viewMap.form.fields.find(field => field.path === 'qualification')

                                const typeOfPropertyListField = repartitionField.viewMap.dt.fields.find(field => field.path === 'typesOfProperty')
                                const typeOfPropertyFormField = repartitionField.viewMap.form.fields.find(field => field.path === 'typesOfProperty')

                                store.dispatch(setFieldVisibility(ownLiabilitiesListField.id, isLegs))
                                store.dispatch(setFieldVisibility(ownLiabilitiesFormField.id, isLegs))

                                store.dispatch(setFieldVisibility(qualificationListField.id, isLegs))
                                store.dispatch(setFieldVisibility(qualificationFormField.id, isLegs))

                                store.dispatch(setFieldVisibility(typeOfPropertyListField.id, !isLifeInsurance))
                                store.dispatch(setFieldVisibility(typeOfPropertyFormField.id, !isLifeInsurance))

                                const typeOfPropertyDisplay = isLegs ? 'categories' : 'naturesOfProperty'

                                store.dispatch(changeFieldProperty(typeOfPropertyListField.id, 'tKey', typeOfPropertyDisplay))
                                store.dispatch(changeFieldProperty(typeOfPropertyFormField.id, 'tKey', typeOfPropertyDisplay))

                                const options = getDataListList(state, 'm-C-liberalityFile.LiberalityFilePart_typesOfProperty')
                                const filteredOptions = options.filter(option => {
                                    return isLegs
                                        ? option.category
                                        : isDonationOrGiftsOnInheritance
                                            ? option.natureOfProperty
                                            : false
                                })

                                store.dispatch(setFieldListOptions(typeOfPropertyFormField.id, _.map(filteredOptions, "id")))

                            }
                        }
                    }
                },
                {path: 'administrator', display: 'fullName', tKey: 'Gestionnaire', filters: ['eligibleToLiberalityProcess'], section: "partiesSection"},

                {path: 'benefactor', section: "partiesSection", required: true},
                {path: 'comment', type: "richTextEditor", section: "legacySection"},

                {path: 'grossAssets', tKey: 'Actif brut (€)', wholePositiveNumber: true, section: "legacySection", default: 0, required: true},
                {path: 'grossAssetsDetails', tKey: 'Détails liés à l’actif brut', type: "richTextEditor", section: "legacySection"},

                {path: 'assets', tKey: 'Actif (€)', wholePositiveNumber: true, section: "legacySection", default: 0, required: true},
                {path: 'assetsDetails', tKey: 'Détails liés à l’actif (€)', type: "richTextEditor", section: "legacySection"},

                {path: 'grossLiabilities', tKey: 'Passif successoral (€)', wholePositiveNumber: true, section: "legacySection", default: 0, required: true},
                {path: 'grossLiabilitiesDetails', tKey: 'Détails liés au passif successoral', type: "richTextEditor", section: "legacySection"},

                {path: 'actsFees', tKey: 'Frais d’actes (€)', wholePositiveNumber: true, section: "legacySection", default: 0, required: true},
                {path: 'actsFeesDetails', tKey: 'Détails liés aux frais d’actes', type: "richTextEditor", section: "legacySection"},

                {
                    path: 'distribution',
                    tKey: 'Répartition',
                    section: "legacySection",
                    viewMap: {
                        dt: [
                            {path: 'confFor', tKey: 'Pour'},
                            {path: 'beneficiary', display: 'zipCodeAndName'},
                            {path: 'qualification', tKey: 'QF'},
                            {path: 'typesOfProperty', tKey: 'categories'},
                            {path: 'ownLiabilities'},
                            {path: 'share', tKey: 'Quote-part'},
                        ],
                        form: [
                                {path: 'confFor', tKey: 'CONF. DEFENSE DE L’ANIMAL POUR'},
                                {path: 'beneficiary', display: 'zipCodeAndName', filters: ['isAssociation'],},
                                {path: 'qualification', required: true,},
                                {path: 'typesOfProperty', fieldPath: ['id', 'name', 'category', 'natureOfProperty'], tKey: 'categories', required: true,},
                                {path: 'ownLiabilities',required: true, default: 0},
                                {path: 'share', tKey: 'Quote-part', required: true, default: 0},
                        ],
                    }
                },

                {path: 'files', tKeyText: "Fichiers", section: "documentsAndMonitoring"},
                {path: 'comments', tKeyText: "Suivi", section: "documentsAndMonitoring"},

                {path: 'applicationNumber', hidden: true},
                {path: 'status', hidden: true},
                {path: 'buttons', hidden: true}

            ],
            onOpen: ({ store, module }) => {
                const state = store.getState()
                const statusId = _.get(state, 'edit.object.data.status.id')
                const partiesSectionFields = module.viewMap.form.fields.filter(field => field.section === "partiesSection")
                const legacySectionFields = module.viewMap.form.fields.filter(field => field.section === "legacySection")
                const lifeInsuranceSectionFields = module.viewMap.form.fields.filter(field => field.section === "lifeInsuranceSection")
                const documentsAndMonitoringFields = module.viewMap.form.fields.filter(field => field.section === "documentsAndMonitoring")

                partiesSectionFields.forEach(field => store.dispatch(setFieldEdition(field.id, ['draft', 'incomplete'].includes(statusId))))
                legacySectionFields.forEach(field => store.dispatch(setFieldEdition(field.id, ['draft', 'incomplete'].includes(statusId))))
                lifeInsuranceSectionFields.forEach(field => store.dispatch(setFieldEdition(field.id, ['draft', 'incomplete'].includes(statusId))))
                documentsAndMonitoringFields.forEach(field => store.dispatch(setFieldEdition(field.id, ['draft', 'incomplete'].includes(statusId))))
            }
        }
    },
    filters: ['byStatus']
}
