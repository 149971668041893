import {changeFieldProperty, setFieldListOptions, setFieldVisibility} from "../../../../../apps/KpModule/actions"
import {GET_OBJECT_SUCCESS} from "../../../../../apps/KpModule/actions/api"

export const module_ = {
    name: 'Decision',
    tKey: 'Décision dossier',
    object: 'ReliefFund',
    category: {path: 'Fonds de secours', icon: 'icon_path'},
    newable: false,
    removable: false,
    viewMap: {
        dt: [
            {path: 'applicationNumber', tKey: 'N° du dossier'},
            {path: 'organizationName'},
            {path: 'object'},
            {path: 'needsTypes'},
            {path: 'totalTotalAmounts', tooltip: true},
            {path: 'totalRequestedAmounts', tooltip: true},
            {path: 'fundingRate'},
            {path: 'status', type: 'status', fieldPath: ['id', 'name', 'bStyle'], translate: true}
        ],
        form: {
            fields: [
                {path: 'applicationNumber', editable: false},
                {path: 'organizationName', editable: false},
                {path: 'object', editable: false},
                {path: 'description', tKey: 'Présentation des difficultés rencontrées', type: 'richTextEditor', editable: false},
                {
                    path: 'needs',
                    tKey: 'Besoins',
                    type: 'accordion',
                    newable: false,
                    viewMap: {
                        dt: [
                            { path: 'natureOfHelp'},
                            { path: 'totalAmount'},
                            { path: 'requestedAmount'},
                            { path: 'awardedAmount'},
                            { path: 'form', tKey: "Forme d’aide", translate: true }
                        ],
                        form: [
                            {
                                path: 'natureOfHelp',
                                editable: false,
                                fieldPath: ['id', 'name', 'eligibleToEmergencyFund', 'facilityRelated'],
                                subscriptions: {
                                    onChange: (newValue, oldValue, {store, module}) => {
                                        const needsField = module.viewMap.form.fields.find(field => field.path === 'needs')
                                        const landTypeField = needsField.viewMap.form.fields.find(field => field.path === 'landType')

                                        store.dispatch(setFieldVisibility(landTypeField.id, !!newValue && newValue.facilityRelated))
                                    }
                                }
                            },
                            {
                                path: 'landType', tKey: "Le dossier concerne un lieu", display: 'name', required: true, editable: false, translate: true, sortList: false,
                                hidden: true,
                                subscriptions: {
                                    onChange: (newValue, oldValue, {store, module}) => {
                                        const needsField = module.viewMap.form.fields.find(field => field.path === 'needs')
                                        const landTypeProofField = needsField.viewMap.form.fields.find(field => field.path === 'landTypeProof')
                                        store.dispatch(setFieldVisibility(landTypeProofField.id, !!newValue))
                                        store.dispatch(changeFieldProperty(landTypeProofField.id, 'tKey', `landTypeProof_${newValue?.id}`))
                                    }
                                }
                            },
                            { path: 'totalAmount', editable: false },
                            {
                                path: 'requestedAmount', editable: false,
                                subscriptions: {
                                    onChange: (newValue, oldValue, {store, module}) => {
                                        if(newValue) {
                                            const needsField = module.viewMap.form.fields.find(field => field.path === 'needs')
                                            const awardedAmountField = needsField.viewMap.form.fields.find(field => field.path === 'awardedAmount')
                                            store.dispatch(changeFieldProperty(awardedAmountField.id, 'maxValue', newValue))
                                        }
                                    }
                                }
                            },
                            { path: 'awardedAmount', required: true, wholePositiveNumber: true },
                            { path: 'form', tKey: "Forme d’aide", required: true, translate: true },
                            { path: 'landTypeProof', required: true, fieldPath: ['id', 'filename', 'user.id', 'user.name'], type: 'simpleFiles', editable: false, hidden: true },
                            { path: 'supportingDocuments', fieldPath: ['id', 'filename', 'user.id', 'user.name'], type: 'simpleFiles', tKey: "needSupportingDocuments_submission", editable: false}
                        ]
                    }
                },
                {path: 'studyCommitteeComment', type: 'richTextEditor', editable: false},
                {path: 'decision', tKey: 'boardOfDirectorsDecision', type: 'richTextEditor'},
                {path: 'comments', tKeyText: 'Suivi'},
                {path: 'submitter', fieldPath: ['id', 'firstname', 'lastname', 'mail'], hidden: true},
                {path: 'ongoingLiberalities', hidden: true},
                {path: 'buttons', hidden: true}
            ]
        }
    },
    actionSubscriptions: [
        {
            actionType: GET_OBJECT_SUCCESS,
            subscription: ({ store, module }) => {
                const state = store.getState()
                const ongoingLiberalities = state.edit.object.data.ongoingLiberalities

                const needsField = module.viewMap.form.fields.find(field => field.path === 'needs')
                const formField = needsField.viewMap.form.fields.find(field => field.path === 'form')

                store.dispatch(setFieldListOptions(formField.id,
                    ongoingLiberalities
                        ? ['exceptionalHelp', 'advanceOnLegacy']
                        : ['exceptionalHelp']
                ))

                //store.dispatch(setFieldEdition('e_needs.e_form', ongoingLiberalities))

            }
        }
    ],
    filters: [
        'userHasAuthorization',
        {
            name: 'byStatusForDecision',
            path: 'statuses',
            client: true,
            object: 'ReliefFundApplicationStatus',
            type: 'tags',
            default: [{id: 'controlled'}],
            filters: [
                {
                    name: 'decisionStatuses',
                    match: object => {
                        return  ['controlled', 'accepted', 'refused', 'closed'].includes(object.id)
                    }
                }
            ],
            display: 'name',
            query: (context) => {
                const statuses =  context.data?.statuses || []
                return { status: {$in: statuses.map(status => status.id)}}
            }
        }
    ]
}
