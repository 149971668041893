export const returnButton = {
    type: 'return',
    tKey: 'return',
    bsStyle: 'default'
}

export const saveButton = {
    type: 'save',
    tKey: 'save',
    bsStyle: 'success'
}

export const validateButton = {
    action: 'validate',
    bsStyle: 'primary',
    tKey: 'validate',
    type: 'action'
}

export const transmitPaymentsButton = {
    action: 'transmitPayments',
    bsStyle: 'primary',
    tKey: 'TRANSMETTRE POUR VALIDATION',
    type: 'action'
}

export const updateDocumentsButton = {
    action: 'updateDocuments',
    bsStyle: 'primary',
    tKey: 'METTRE À JOUR LES PIÈCES JUSTIFICATIVES',
    type: 'action',
    pristine: true
}

export const closePaymentsButton = {
    action: 'closePayments',
    bsStyle: 'warning',
    tKey: 'CLÔTURER LE DOSSIER',
    type: 'action',
    getUserConfirmation: true,
    confirmationMessage: 'closeFileConfirmationMessage',
}

export const refuseButton = {
    action: 'refuse',
    bsStyle: 'danger',
    tKey: 'refuse',
    type: 'action',
    bypassFormValidation: true
}

export const rectifyButton = {
    action: 'rectify',
    bsStyle: 'warning',
    tKey: 'toRectify',
    type: 'action'
}

export const submitButton = {
    action: 'submit',
    bsStyle: 'primary',
    getUserConfirmation: true,
    confirmationMessage: 'submissionConfirmationMessage',
    tKey: 'JE TRANSMETS MON DOSSIER',
    type: 'action'
};

export const updateButton = {
    action: 'update',
    bsStyle: 'primary',
    getUserConfirmation: true,
    confirmationMessage: 'submissionConfirmationMessage',
    tKey: 'JE TRANSMETS MON DOSSIER',
    type: 'action'
};

export const acceptButton = {
    action: 'accept',
    bsStyle: 'primary',
    tKey: 'accept',
    type: 'action'
};

export const examineButton = {
    action: 'examine',
    bsStyle: 'warning',
    tKey: 'Demander compléments',
    type: 'action',
    bypassFormValidation: true
};

export const justifyButton = {
    action: 'justify',
    bsStyle: 'warning',
    tKey: 'toJustify',
    type: 'action',
    bypassFormValidation: true
};

export const requestButtons = [
    validateButton,
    saveButton,
    returnButton
]

export const controlButtons = [
    rectifyButton,
    validateButton,
    refuseButton,
    returnButton
]

export const validationButtons= [
    justifyButton,
    validateButton,
    refuseButton,
    returnButton
]
