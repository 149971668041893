const moment = require("moment");
const _ = require("lodash");
const {generateAlertReport, generateIndicatorReport, generateTreatedAlertsReport} = require("./utils/generateReport")
const {setFieldVisibility} = require("../../../apps/KpModule/actions")
const {getDataListList} = require("../../../apps/KpModule/selectors")

export const dependencies = [
    {
        name: "ReportType",
        type: "static",
        fields: [
            {path: "id", type: "string"},
            "name"
        ],
        objects: [
            {id: "indicator", name: "indicators"},
            {id: "alert", name: "alerts"},
            {id: "treatedAlerts", name: "treatedAlertsReport"}
        ]
    },
    {
        name: "Periodicity",
        type: "static",
        fields: [
            {path: "id", type: "string"},
            "name"
        ],
        objects: [
            {id: "day", name: "day"},
            {id: "week", name: "week"},
            {id: "month", name: "month"}
        ]
    }
]

export const entity = {
    name: "Report",
    facets: [{name: "files", linkType: "OTO"}],
    fields: [
        "title",
        {path: "date", type: "date", dateFormat: 'YYYY-MM-DD HH:mm:ss', nullable: true},
        {path: "period", type: "dateRange"},
        {path: "periodicity", type: "Periodicity", nullable: true},
        {
            path: "user",
            type: "User",
            link: "MTO",
            nullable: true
        },
        {path: "cumulative", type: "boolean"},
        {path: "alertConfigurations", type: "AlertConfiguration", link: "MTM"},
        {
            type: "ReportType",
            link: "MTO"
        },
        {path: "indicators", type: "ThemeJoin", link: "MTM", nullable: true}
        //{path: "automatic", type: "boolean", default: true}
    ],
    beforeSave: function(object, oldObject, context, callback) {
        object.user = _.pick(context.user, ['id', 'name'])
        object.date = moment().format('YYYY-MM-DD HH:mm:ss')
        callback(null, object, oldObject)
    },
    afterSave: function(object, oldObject, context, callback) {
        let reportToLaunch

        //treatedAlerts
        if(object.reportType.id === 'alert') reportToLaunch = generateAlertReport
        if(object.reportType.id === 'indicator') reportToLaunch = generateIndicatorReport
        if(object.reportType.id === 'treatedAlerts') reportToLaunch = generateTreatedAlertsReport
        reportToLaunch(object, context)
            .then(object => {
                global.ioSocket.emit(
                    `fetchEditObject-success-${context.user.id}${context.clientContext.accessId}`,
                    object
                )
            })
            .catch(err => {
                global.ioSocket.emit(
                    `fetchEditObject-failure-${context.user.id}${context.clientContext.accessId}`,
                    {error: err.message}
                )
            })

        callback()
    }
}

export const module_ = {
    object: "Report",
    tKey: "mTitle_report",
    category: {
        path: "tracking",
        icon: 'briefcase'
    },
    newable: true,
    editable: false,
    useSocketsOnSave: true,
    defaultSortBy: 'date',
    defaultSortDirection: 'DESC',
    viewMap: {
        dt: [
            {path: "title", type: "translatedText"},
            {path: "reportType", translate: true},
            {path: "period", type: "dateRange"},
            {path: "periodicity"},
            {path: "date", tKey: 'generatedAt'},
            "file"
            //{path: "user", display: "name"},
        ],
        form: {
            fields: [
                {path: "title", type: "textarea"},
                {
                    path: "reportType",
                    translate: true,
                    subscriptions : {
                        onChange: (newValue, oldValue, {module, store, formInitialize, getObjectSuccessAction}) => {
                            const isIndicatorReport = newValue && newValue.id === "indicator"
                            const isAlertReport = newValue && newValue.id === "alert"
                            const isTreatedAlertsReport = newValue && newValue.id === "treatedAlerts"

                            const periodicityField = module.viewMap.form.fields.find(field => field.path === 'periodicity')
                            const cumulativeField = module.viewMap.form.fields.find(field => field.path === 'cumulative')
                            const periodField = module.viewMap.form.fields.find(field => field.path === 'period')

                            store.dispatch(setFieldVisibility("e_indicators", isIndicatorReport))
                            store.dispatch(setFieldVisibility("e_alertConfigurations", isAlertReport))
                            store.dispatch(setFieldVisibility("e_periodicity", isAlertReport || isIndicatorReport))
                            store.dispatch(setFieldVisibility("e_cumulative", isAlertReport || isIndicatorReport))

                            const isFormInit = formInitialize || getObjectSuccessAction
                            if(isTreatedAlertsReport && !isFormInit) {
                                const state = store.getState()
                                const list = getDataListList(state, 'Report-GeneralSettings')
                                const generalSettings = list[0]

                                const fiscalYearStartDate = `${generalSettings.month?.id}-${generalSettings.monthNumber?.id}`
                                const periodEnd = moment.utc()

                                const fiscalYearStart = moment.utc(fiscalYearStartDate, 'MM-DD')

                                const periodStart = periodEnd.isSameOrAfter(fiscalYearStart)
                                    ? fiscalYearStart
                                    : fiscalYearStart.subtract(1, 'years')

                                periodField.setValue([periodStart, periodEnd].map(date => date.format('YYYY-MM-DD')))

                                periodicityField.setValue(null)
                                cumulativeField.setValue(false)
                            }
                            if(!isTreatedAlertsReport && !isFormInit) {
                                const toDaysDate = moment.utc()
                                periodField.setValue([toDaysDate, toDaysDate].map(date => date.format('YYYY-MM-DD')))
                            }
                        }
                    }
                },
                {path: "period", type: "dateRange"},
                {path: "alertConfigurations", translateName: true},
                {path: "indicators", display: 'completeName', fieldPath: ['id', 'code', 'name', 'completeCode', 'completeName', 'mtKeyName'], translateName: true},
                {path: "periodicity"},
                {path: "cumulative", tKey: "cumuler les résultats"}
            ],
            dataLists: [
                "Report-GeneralSettings"
            ],
            onOpen: ({ store }) => {
                const state = store.getState()
                const isIndicatorReport = state.edit.object.data?.reportType?.id === "indicator"
                const isAlertReport = state.edit.object.data?.reportType?.id === "alert"
                store.dispatch(setFieldVisibility("e_indicators", isIndicatorReport))
                store.dispatch(setFieldVisibility("e_indicators", isAlertReport))
            }
        }
    },
    accesses: [
        {
            id: "Report-GeneralSettings",
            entity: "GeneralSettings",
            fieldPath: ['month', 'monthNumber']
        },
    ],
    filters: [
        {
            path: "byUser",
            query: function(context){
                const userId = _.get(context, "user.id")
                return userId
                    ? {user: global.ObjectID(userId)}
                    : {_id: null}
            }
        },
        /*
        {
            type: "datePicker",
            path: "date",
            default: moment().format("YYYY-MM-DD"),
            client: true,
            match: function (object, context) {
                const filterDate = context.data.date;
                const date = moment(object.date).format("YYYY-MM-DD");
                return filterDate === date;
            }
        }
         */
    ],
}
